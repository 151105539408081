import React from 'react';

export const Input = (props) => {
  return ( 
    <input 
      type={props.type} 
      className={`${props.className} text-gray-800 border-t border-b border-l border-r outline-none border-black `} 
      placeholder={props.placeholder}
      onChange={props.onChange}
      value={props.value}
      name={props.name}
      required={props.required}
    />
  );
}
