import React, { useContext } from 'react';
import { Header, Footer, SideBar, Backdrop } from '../index';
import { ThemeContext, setThemeContext } from '../../../providers/ThemeProvider';
import { ToggleContext, SetToggleContext } from '../../../providers/ToggleProvider';
import useToggle from '../../../hooks/useToggle';
import { SEO } from '../../Common';
import './Layout.scss';

export const Layout = (props) => {
  const [ isElement, setToogleElement ] = useToggle(false);
  const theme = useContext(ThemeContext);
  const setTheme = useContext(setThemeContext);
  const seoElement = useContext(ToggleContext);
  const toggleSeoElement = useContext(SetToggleContext);
  const selectedTheme = theme === 'dark' ? 'dark-theme' : 'light-theme';
  toggleSeoElement(true);

  return ( 
    <>
      {seoElement && <SEO typeWebsite />}
      <Header 
        toggleSidebar={setToogleElement} 
        theme={theme}
        setTheme={setTheme}
      />
      { isElement && <Backdrop toggleBackdrop={setToogleElement} /> }
      <SideBar 
        toggleSidebar={setToogleElement} 
        setTheme={setTheme}
        isSideBar={isElement}
        theme={theme}
      />
      <main className={`main-container ${selectedTheme}`}>
        {props.children}
      </main>
      <Footer />
    </>
  );
}