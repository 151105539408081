import React from 'react';

const styles = {
  background: 'rgba(0, 0, 0, 0.5)',
}

export const Backdrop = (props) => {
  return ( 
    <section onClick={props.toggleBackdrop} onKeyDown={props.toggleBackdrop} style={styles} className="fixed z-20 h-full w-full">
    </section>
  );
}
 