import React from 'react';
import { navigate } from 'gatsby';
import { Link, FormattedMessage, useIntl } from 'gatsby-plugin-intl';
import { FaMoon, FaTimes, FaSun } from 'react-icons/fa';
import { selectLanguaage } from '../../../utils/selectLanguage';
import './SideBar.scss';

export const SideBar = (props) => {
  const {theme, setTheme} = props;
  const intl = useIntl();
  const moveSideBar = props.isSideBar ? '' : 'move-left'
  return ( 
    <nav className={`sidebar py-8 px-8 fixed z-30 h-full ${moveSideBar} ${theme === 'dark' ? 'dark-theme' : 'light-theme'}`}>
      <div onClick={props.toggleSidebar} style={{ display: 'flex', justifyContent: 'flex-end'}}>
        <FaTimes style={{ fontSize: '3rem', textAlign: 'left' }} />
      </div>
      <ul className="navagation flex flex-col justify-center items-center ">
        <Link to="/" activeClassName="active">
          <li className="my-2 text-3xl" >
            <FormattedMessage id="navLink1" />
          </li>
        </Link>
        <Link to="/about" activeClassName="active">
          <li className="my-2 text-3xl" >
            <FormattedMessage id="navLink2" />
          </li>
        </Link>
        <Link to="/blog" activeClassName="active">
          <li className="my-2 text-3xl" >
            <FormattedMessage id="navLink3" />
          </li>
        </Link>
        <Link to="/contact" activeClassName="active">
          <li className="my-2 text-3xl" >
            <FormattedMessage id="navLink4" />
          </li>
        </Link>
         <li className="text-3xl mx-4 cursor-pointer">
          <span onClick={() => navigate(selectLanguaage(intl.locale))}>
            { intl.locale === 'en' ? 'ES' : 'EN' }
          </span>
        </li>
        <li 
          className="my-2 text-4xl"
          onClick={() => theme === 'dark' ? setTheme('light') : setTheme('dark')}  >
          {props.theme ? <FaSun /> : <FaMoon /> }
        </li>
      </ul>
    </nav>
  );
}