import React, { useContext } from 'react';
import { BlogPostJsonLd, GatsbySeo } from 'gatsby-plugin-next-seo';
import { LocationContext } from '../../../providers/LocationProvider';
import siteConfig from '../../../data/siteConfig';

export const SEO = (props) => {
  const location  = useContext(LocationContext);

  return (
    props.typeWebsite ?
      <GatsbySeo
        title={siteConfig.siteTitle}
        titleTemplate={`%s | ${location}`}
        description={siteConfig.siteDescription}
        openGraph={{
          type: 'website',
          url: siteConfig.siteUrl,
          title: siteConfig,
          description: siteConfig.siteDescription,
        }}
        twitter={{
          handle: '@handle',
          site: '@site',
          cardType: 'summary_large_image',
        }}
      /> 
       :
      <>
        <GatsbySeo
          title={siteConfig.siteTitle}
          titleTemplate={`%s | ${props.postTitle}`}
          openGraph={{
            title: props.postTitle,
            description: `${props.postDescription.substring(0, 150)}...`,
            url: props.postUrl,
            type: 'article',
            article: {
              publishedTime: props.postDate,
              modifiedTime: props.postDate,
              authors: [
                `${siteConfig.siteUrl}/authors/@Holiviel-Valdez`,
  
              ],
            },
            images: [
              {
                url: props.postImage,
                width: 850,
                height: 650,
                alt: props.postAlt,
              },
            ],
          }}
          twitter={{
            handle: '@handle',
            site: '@site',
            cardType: 'summary_large_image',
          }}
        />
        <BlogPostJsonLd
          url={props.postUrl}
          title={props.postTitle}
          images={props.postImage}
          datePublished={props.postDate}
          dateModified={props.postDate}
          authorName={siteConfig.siteTitle}
          description={`${props.postDescription.substring(0, 150)}...`}
        />
      </>
  )
}