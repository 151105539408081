const config = {
  siteTitle: 'Holiviel Valdez', // Site title.
  siteUrl: 'https://holivielvaldez.dev', // Domain of your website without pathPrefix.
  favicon_url: '../../static/favicon.ico',
  author: 'Holiviel Valdez',
  // emailLinkEn: 'https://mailchi.mp/cdf25da2c0e2/javascript-station',
  // emailLinkEs: 'https://mailchi.mp/200a5626c494/javascript-latam',
  pathPrefix: "/", // Prefixes all links. For cases when deployed to example.github.io/gatsby-advanced-starter/.
  siteDescription: 'holivielvaldez.com is a personal website about my projects and blog about the latest technology of the front end development and jamstack world', // Website description used for RSS feeds/meta description tag.
  siteDescriptionEs: 'holivielvaldez.com es un sitio web personal sobre mis proyectos y un blog sobre la última tecnología del desarrollo front end y el mundo del jamstack', // Website description used for RSS feeds/meta description tag.
  userLocation: 'Santo Domingo, Dominican Republic', // User location to display in the author segment.
  // PWA DATA
  name: 'Holiviel Valdez',
  shortName: 'Holiviel Valdez',
  start_url: '/',
  themeColor: '#f9f9f9', // Used for setting manifest and progress theme colors.
  backgroundColor: '#f9f9f9', // Used for setting manifest background color.
  display: 'standalone',
  icon: `static/favicon.ico`,
};

module.exports = config;
