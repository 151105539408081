import React from 'react';
import { useIntl } from 'gatsby-plugin-intl';
import PersonalImage from '../../../assets/images/foto.jpeg';
import textEn from '../../../intl/en.json';
import textEs from '../../../intl/es.json';
import './Bio.scss';

export const Bio = (props) => { 
  const intl = useIntl();
  const seletecedLanguage = intl.locale === 'en' ? textEn.bioDescription : textEs.bioDescription;
  return ( 
    <section className="bright-blue rounded flex flex-col md:flex-row items-center mt-12 py-10 md:py-12 px-10">
      <img src={PersonalImage} alt="my personal photo" className="h-40 w-40 rounded-full" />
      <p className="bio-text-size ml-6 mb-6 text-white mt-6" >
        {seletecedLanguage}
      </p>      
    </section>
  );
}