import React, { useContext } from 'react';
import { useIntl } from 'gatsby-plugin-intl';
import { ThemeContext} from '../../../providers/ThemeProvider';
import { Link, FormattedMessage } from 'gatsby-plugin-intl';
import { OutboundLink } from 'gatsby-plugin-google-analytics';
import { FaGithub, FaTwitter, FaInstagram, FaLinkedin, FaYoutube } from 'react-icons/fa';
import Lightlogo  from '../../../assets/images/light-logo.svg';
import './Footer.scss';

export const Footer = (props) => {
  const { locale } = useIntl();
  const theme = useContext(ThemeContext);

  return ( 
    <>
    <footer className="footer text-white px-24 pt-40 py-32  text-3xl">
      <section className="flex flex-col items-center md:flex-row md:justify-around md:w-4/5 md:mx-auto"> 
        <div className="flex flex-col items-center  md:flex-row">
          <Link to="/">
            <img src={Lightlogo} alt="logo" style={{ height: 42 }} />
          </Link>
        </div>
        <ul className="my-5">
          <Link to="/about">
            <li className="mt-4 text-center text-3xl">
              <FormattedMessage id="footerLink1" />
            </li>
          </Link>
          <Link to="/contact">
            <li className="mt-4 text-center text-3xl" >
              <FormattedMessage id="footerLink2" />
            </li>
          </Link>
          <Link to="/blog">
            <li className="mt-4 text-center text-3xl" >
              <FormattedMessage id="footerLink3" />
            </li>
          </Link>
        </ul>
        <div> 
        <p className="text-center text-3xl mb-4" >
          <FormattedMessage id="footerMedia" />
        </p>
          <ul className="flex">
            <li className="mr-4">
              <OutboundLink  href="https://github.com/oliviel" target="_blank" rel="noopener noreferrer">
                <FaGithub />
              </OutboundLink>
            </li>
            <li className="mr-4">
              <OutboundLink href={`${locale === 'en' ? 'https://twitter.com/holiviel_en' : 'https://twitter.com/holiviel_es' }`} target="_blank" rel="noopener noreferrer">
                <FaTwitter />
              </OutboundLink>
            </li>
            <li className="mr-4">
              <OutboundLink href={`${locale === 'en' ? 'https://www.instagram.com/holiviel_valdez_en' : 'https://www.instagram.com/holiviel_valdez' }`} target="_blank" rel="noopener noreferrer">
                <FaInstagram />
              </OutboundLink>
            </li>
            <li className="mr-4">
              <OutboundLink href="https://www.linkedin.com/in/hordanel-valdez-488ba610a/" target="_blank" rel="noopener noreferrer">
                <FaLinkedin />
              </OutboundLink>
            </li>
            <li className="mr-4">
              <OutboundLink href="https://www.youtube.com/channel/UC2yllAaom7paEMqNJQGF8jw" target="_blank" rel="noopener noreferrer">
                <FaYoutube />
              </OutboundLink>
            </li>
          </ul> 
        </div>
      </section>
    </footer>
    </>
  );
}
