import React from 'react';

export const Form = (props) => {
  return ( 
    <form 
      action={props.action} 
      className={props.className} 
      onSubmit={props.onSubmit}
    >
      {props.children}
    </form>
  );
}