import React from 'react';

export const Button = (props) => {
  return ( 
    <button 
      className={`${props.className} rounded-lg cursor-pointer border-0`}>
      {props.text}
    </button>
  );
}
