import { useState } from 'react';

export default (inittialValue) => {
  const [ state, setSate ] = useState(inittialValue);

  function toggle() {
    setSate(!state);
  }

  return [ state, toggle ];
}
