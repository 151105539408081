import React from 'react';
import { navigate } from 'gatsby';
import { FaMoon,  FaSun } from 'react-icons/fa';
import { Link, FormattedMessage, useIntl } from 'gatsby-plugin-intl';
import Darklogo  from '../../../assets/images/dark-logo.svg';
import Lightlogo  from '../../../assets/images/light-logo.svg';
import { selectLanguaage } from '../../../utils/selectLanguage';
import './Header.scss';

export const Header = (props) => {
  const {theme, setTheme} = props;

  const intl = useIntl();
  const selectedHeaderTheme = theme === 'dark' ? 'dark-header-custom' : 'light-theme';
  const selectedHambugerTheme = theme === 'dark' ? 'ligth-button' : 'dark-button';
  const selectedLogo = theme === 'dark' ? Lightlogo : Darklogo;
  
  return ( 
    <header className={`header py-6 md:py-8 px-4 z-10 fixed w-full ${selectedHeaderTheme}`}>
      <nav className="navbar mx-auto flex justify-between items-center md:w-4/5">
        <Link to="/">
          <div>
            <img src={selectedLogo} alt="logo" className="header-logo" />
          </div>
        </Link>
        <ul className="hidden md:flex">
          <li className="text-3xl mx-4 cursor-pointer">
            <Link to="/" activeClassName="active">
              <FormattedMessage id="navLink1" />
            </Link>
          </li>
          <li className="text-3xl mx-4 cursor-pointer">
            <Link to="/about" activeClassName="active">
              <FormattedMessage id="navLink2" />
            </Link>
          </li>
          <li className="text-3xl mx-4 cursor-pointer">
            <Link to="/blog" activeClassName="active">
              <FormattedMessage id="navLink3" />
            </Link>
          </li>
          <li className="text-3xl mx-4 cursor-pointer">
            <Link to="/contact" activeClassName="active" >
              <FormattedMessage id="navLink4" />
            </Link>
          </li>
          <li className="text-3xl mx-4 cursor-pointer">
            <span onClick={() => navigate(selectLanguaage(intl.locale))}>
              { intl.locale === 'en' ? 'ES' : 'EN' }
            </span>
          </li>
          <li onClick={ () => theme === 'dark' ? setTheme('light') : setTheme('dark') }  className="cursor-pointer text-4xl">
            { theme === 'dark' ? <FaSun /> : <FaMoon /> }
          </li>
          {/* <li onClick={props.toggleTheme}  className="cursor-pointer text-4xl">
            { props.theme ? <FaSun /> : <FaMoon /> }
          </li> */}
        </ul> 
        <button onClick={props.toggleSidebar} className="focus:outline-none py-0 w-12 bg-transparent md:hidden">
          <span className={`block w-full h-1 my-2 ${selectedHambugerTheme}`}></span>
          <span className={`block w-10 ml-auto h-1 my-2 ${selectedHambugerTheme}`}></span>
          <span className={`block w-full h-1 my-2 ${selectedHambugerTheme}`}></span>
        </button>
      </nav>
    </header>
  );
}